import { db } from '@/db'

const tokenDbKey = 'hozSsv:authEndpoint'

export const authStore = {
  async saveToken(token) {
    const dbInstance = await db()
    await dbInstance.auth.put({ tokenKey: tokenDbKey, tokenValue: token })
  },
  async getToken() {
    const dbInstance = await db()
    const tokenObj = await dbInstance.auth.get(tokenDbKey)
    return tokenObj ? tokenObj.tokenValue : null
  }
}
