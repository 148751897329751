<script setup>
import { RouterView } from 'vue-router'
import { useConnectionInfoStore, useHeartbeat } from '@/monitoring'
import { watch } from 'vue'
import { useUnsentValidationWorker } from './validation'

const { connectionState } = useHeartbeat()
const connectionInfoStore = useConnectionInfoStore()

watch(connectionState, (newState) => {
  // store current connection state so that other components can query connection state anywhere
  connectionInfoStore.switchStateTo(newState)
})

useUnsentValidationWorker()
</script>

<template>
  <div class="h-screen">
    <RouterView />
  </div>
</template>
