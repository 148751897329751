<script setup>
import BaseInput from '@/components/BaseInput.vue'
import { ref, unref } from 'vue'
import { useAuth } from '@/auth'
import BaseButton from './BaseButton.vue'

const { authenticate, isLoading } = useAuth()

const identifier = ref('')
const passcode = ref('')
const authError = ref(false)

async function onSubmit() {
  authError.value = false
  try {
    await authenticate({
      credentials: {
        identifier: unref(identifier),
        passcode: unref(passcode)
      }
    })
  } catch (e) {
    console.error(e.message)
    authError.value = true
  }
}
</script>

<template>
  <div class="h-full">
    <div data-cy="messages">
      <span v-if="authError" class="text-hozah-red-600 text-3v"
        >Incorrect login details. Please check them and try again.</span
      >
    </div>
    <form @submit.prevent="onSubmit">
      <BaseInput v-model="identifier" label="ID/IDENTIFIER" name="siteIdentifier" />
      <BaseInput
        v-model="passcode"
        class="mb-4v mt-4v"
        label="PASSCODE"
        name="passcode"
        type="password"
      />
      <BaseButton :is-loading="isLoading" data-cy="login-button" label="Log in" />
    </form>
  </div>
</template>
