import { db } from '@/db'

// IndexedDB-based FIFO queue of the unsent VRM validations
export const validationsQueue = {
  async enqueue({ vrm, recordedAt }) {
    const dbInstance = await db()
    await dbInstance.validationsQueue.put({
      vrm,
      recordedAt: recordedAt.toJSDate(),
      inFlight: false
    })
  },

  /**
   * Takes next unsent validation from the queue,
   * locking them item to prevent competing consumers from borrowing the same items.
   *
   * undefined will be returned if queue is empty
   */
  async borrowNext() {
    const dbInstance = await db()
    return dbInstance.transaction('rw', dbInstance.validationsQueue, async () => {
      const unsentValidations = await dbInstance.validationsQueue
        .orderBy('recordedAt')
        .filter((e) => !e.inFlight)
        .toArray()
      const nextUnsent = unsentValidations.shift()
      if (nextUnsent !== undefined) {
        nextUnsent.inFlight = true
        dbInstance.validationsQueue.put(nextUnsent)
      }

      return nextUnsent
    })
  },

  async remove(id) {
    const dbInstance = await db()
    await dbInstance.validationsQueue.delete(id)
  },

  async release(id) {
    const dbInstance = await db()
    await dbInstance.validationsQueue.update(id, { inFlight: false })
  }
}
