import { createApp } from 'vue'
import { createPinia } from 'pinia'

import { createAuth } from './auth'

import App from './App.vue'
import router from './router'
import { appConfig } from './config'
import { makeStubServer } from './server'

import './index.css'
import { registerSW } from 'virtual:pwa-register'
import * as Sentry from '@sentry/vue'

registerSW({
  immediate: true,
  onRegistered(r) {
    r &&
      setInterval(
        () => {
          r.update()
        },
        15 * 60 * 1000 // Check every 15 mins whether there is a new version on the backend
      )
  }
})

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://94b0d2a664d99e8e6732a1231e57c5f2@o4504893571268608.ingest.sentry.io/4506519157800960',
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

app.use(createPinia())
app.use(router)
app.use(createAuth(), {
  apiBaseUrl: appConfig.apiBaseUrl,
  redirectTo: '/vrm'
})

if (import.meta.env.MODE === 'development') {
  makeStubServer()
}

app.mount('#app')
