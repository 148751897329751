import { createAuthHolder } from './holder'
import { Auth } from '@/injectKeys.js'
import { ref, unref } from 'vue'

export const authHolder = ref(null)

export const createAuth = () => ({
  install: (app, options) => {
    if (!app.config.globalProperties.$router) {
      throw Error(
        'Vue Router plugin is not registered. Make sure to call app.use(router) before Auth plugin is installed'
      )
    }
    const { redirectTo = '/' } = options
    const holder = createAuthHolder({
      apiBaseUrl: options.apiBaseUrl,
      router: app.config.globalProperties.$router,
      redirectTo
    })
    authHolder.value = holder
    holder.tryRestoringAuth()
    app.provide(Auth, holder)
  }
})

export const authGuard = async () => {
  const holder = unref(authHolder)
  if (holder.isAuthenticated) {
    return true
  } else {
    return { path: '/' }
  }
}

export * from './useApi'
