import { ref, watch } from 'vue'
import { useAuth } from '@/auth'
import { useApi } from '@/api'
import { useIntervalFn } from '@vueuse/core'
import { defineStore } from 'pinia'
import { DateTime } from 'luxon'

const defaultIntervalSecs = 5
const defaultTimeoutSecs = 11

/**
 * Sets up period heartbeat monitoring.
 *
 * This function serves dual purpose: it sends heartbeat messages to backend which helps to
 * remotely monitor working endpoint client (browser running on Tablet with page opened)
 * and also to monitor device connectivity so other parts of the application can make decisions depending on the connection status)
 */
export const useHeartbeat = ({
  timeoutSecs = defaultTimeoutSecs,
  heartbeatIntervalSecs = defaultIntervalSecs
} = {}) => {
  const { isAuthenticated } = useAuth()
  const lastConnected = ref(DateTime.utc())
  const connectionState = ref('UP')
  const { exec } = useApi({ url: '/heartbeat' })

  watch(
    isAuthenticated,
    () => {
      if (isAuthenticated.value) {
        useIntervalFn(async () => {
          try {
            await exec({
              method: 'POST',
              data: {
                sent_at: DateTime.utc().toISO()
              }
            })
            lastConnected.value = DateTime.utc()
            connectionState.value = 'UP'
          } catch (e) {
            /* empty */
          } finally {
            const secsSinceLastConnected = Math.abs(lastConnected.value.diffNow('seconds').seconds)
            if (secsSinceLastConnected >= timeoutSecs) {
              connectionState.value = 'DOWN'
            }
          }
        }, heartbeatIntervalSecs * 1000)
      }
    },
    { immediate: true }
  )
  return {
    connectionState,
    lastConnected
  }
}

export const useConnectionInfoStore = defineStore('connectionInfo', () => {
  const connectionState = ref('UP')

  async function switchStateTo(newState) {
    connectionState.value = newState
  }

  return {
    connectionState,
    switchStateTo
  }
})
